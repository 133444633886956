const NewAreaModal = ({ createSection }) => {
  return (
    <div className="modal" tabIndex="-1" id="newAreaModal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">New section</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form className="needs-validation">
            <div className="modal-body">
              <label htmlFor="sectionName" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="sectionName"
                placeholder="Title"
                required
              />
              <label htmlFor="sectionDescription" className="form-label">
                Description
              </label>
              <textarea
                type="textarea"
                className="form-control"
                id="sectionDescription"
                placeholder="Description"
              />
            </div>
            <div className="modal-footer">
              <button
                id="saveArea"
                className="btn btn-primary"
                onClick={createSection}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewAreaModal;
