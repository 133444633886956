import { useState } from "react";
import Payment from "./Payment";
import CurrentPlanView from "./CurrentPlanView";

function Subscriptions() {
  const [showPayments, setShowPayments] = useState(false);
  const [plan, setPlan] = useState("BASIC"); // "BASIC", "PREMIUM"

  return (
    <>
      {showPayments ? (
        <Payment showPayment={setShowPayments} plan={plan} />
      ) : (
        <CurrentPlanView showPayment={setShowPayments} setPlan={setPlan} />
      )}
    </>
  );
}

export default Subscriptions;
