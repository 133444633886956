import { useContext, useEffect, useState } from "react";
import logo from "../../img/logo.jpg";
import AccountMenu from "../account/AccountMenu";
import ApiClient from "../../api/ApiClient";
import { StoreContext } from "../../api/StoreContext";
const Menu = ({ newNodeAction, newAreaAction, sectionId }) => {
  const apiStore = useContext(StoreContext);
  const [sections, setSections] = useState();
  const apiClient = ApiClient();

  useEffect(() => {
    if (apiStore && apiStore.sections && apiStore.sections.length > 0) {
      setSections(apiStore.sections);
    }
  }, [apiStore, sections]);

  return (
    <div id="main-menu">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarToggler">
            <div className="left-menu align-top">
              <a className="navbar-brand" href="/">
                <img src={logo} alt="" />
              </a>
              <AccountMenu />
              <div className="level-tree">
                <div className="level-tree-content">
                  <ul>
                    <li>
                      <a href={apiClient.getDashboardPath()}>
                        <i className="bi bi-house-fill"></i>Home
                      </a>
                      <ul>
                        {sections &&
                          sections.map((section) => (
                            <li key={section.id}>
                              <span></span>
                              <a
                                className={
                                  sectionId === section.id
                                    ? "menu-item menu-item-active"
                                    : "menu-item"
                                }
                                href={
                                  apiClient.getDashboardPath() +
                                  "/section/" +
                                  section.id
                                }
                              >
                                {section.name && section.name.length > 20
                                  ? section.name.substring(0, 20) + "..."
                                  : section.name}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </li>
                  </ul>
                  <hr />
                  {sectionId ? (
                    <button
                      className="btn btn-primary mb-3"
                      id="newNodeButton"
                      onClick={newNodeAction}
                    >
                      Create container
                    </button>
                  ) : (
                    <button
                      className="btn btn-success mb-3"
                      id="newAreaButton"
                      onClick={newAreaAction}
                    >
                      Create section
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Menu;
