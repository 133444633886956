import Premium from "./Premium";
import { ButtonWrapper } from "./ButtonWrapper";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const Basic = ({ showUpgrade, showSubscribe }) => {
  return (
    <>
      <div className="card">
        <div className="card-header">Basic</div>
        <div className="card-body">
          <div className="card-title">
            <span className="plan-price">
              <b>9$</b>
            </span>{" "}
            per user per month
          </div>
          <ul className="list-group">
            <li className="list-group-item">
              sections{" "}
              <span className="badge bg-primary rounded-pill float-end">3</span>
            </li>
            <li className="list-group-item">
              accounts
              <span className="badge bg-primary rounded-pill float-end">1</span>
            </li>
            <li className="list-group-item">
              containers{" "}
              <span className="badge bg-primary rounded-pill float-end">
                20
              </span>
            </li>
            <li className="list-group-item">
              API{" "}
              <span className="badge bg-danger rounded-pill float-end">
                <i className="bi bi-x-lg"></i>
              </span>
            </li>
            <li className="list-group-item">
              Permissions{" "}
              <span className="badge bg-danger rounded-pill float-end">
                <i className="bi bi-x-lg"></i>
              </span>
            </li>
          </ul>
        </div>
        {showSubscribe ? (
          <PayPalScriptProvider
            options={{
              clientId: process.env.REACT_APP_CLIENT_ID,
              components: "buttons",
              intent: "subscription",
              vault: true,
            }}
          >
            <ButtonWrapper
              type="subscription"
              planId={process.env.REACT_APP_BASIC_PLAN_ID}
            />
          </PayPalScriptProvider>
        ) : null}
      </div>
      {showUpgrade ? (
        <div className="container mt-2">
          <div className="row">
            <h3>Upgrade plan</h3>
          </div>
          <div className="row">
            <div className="col col-6">
              <Premium showSubscribe={true} />
            </div>
            <div className="col"></div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default Basic;
