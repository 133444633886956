import { useEffect, useState } from "react";
import ApiClient from "../api/ApiClient";

const Auth = (apiStore) => {
  const apiClient = ApiClient();
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (apiStore && apiStore.userData) {
      setUserData(apiStore.userData);
    }
  }, [apiStore, userData]);

  const logout = () => {
    apiClient.logout();
  };

  const getUserData = () => {
    return userData;
  };

  const isLogged = () => {
    const userData = getUserData();
    if (!userData) {
      return false;
    }
    return userData.id !== process.env.REACT_APP_ANONYMOUS_USER_ID;
  };

  return {
    isLogged: isLogged(),
    getUserData: getUserData(),
    logout: () => logout(),
  };
};

export default Auth;
