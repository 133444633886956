import { PayPalButtons } from "@paypal/react-paypal-js";
export const ButtonWrapper = ({ setPlan, planId }) => {
  function subscriptionApproved(data, actions) {
    fetch("/api/payment/subscription", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.error) {
        alert("Error: " + response.error);
        return;
      }
      setPlan(planId);
    });
  }

  return (
    <PayPalButtons
      onApprove={subscriptionApproved}
      createSubscription={(data, actions) => {
        return actions.subscription
          .create({
            plan_id: planId,
            payment_source: {
              paypal: {
                experience_context: {
                  brand_name: "Appulses.com",
                  locale: "en-US",
                  user_action: "SUBSCRIBE_NOW",
                  shipping_preference: "NO_SHIPPING",
                },
              },
            },
          })
          .then((orderId) => {
            return orderId;
          });
      }}
      style={{
        label: "subscribe",
      }}
    />
  );
};
