import { useState } from "react";
import * as DOMPurify from "dompurify";
import { marked } from "marked";
import ApiUploadForm from "./ApiUploadForm";

const Description = ({ container }) => {
  const [readmeUploaded] = useState(false);

  const DescriptionRenderer = ({ content }) => {
    if (!content)
      return (
        <div>
          {!readmeUploaded && (
            <p>
              No description available. Please upload you readme in plain text
              or markdown.
            </p>
          )}
          <ApiUploadForm
            containerId={container.id}
            uploadPath={"/descriptionFile"}
          />
        </div>
      );
    return (
      <div
        className="description-content"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(marked.parse(content)),
        }}
      ></div>
    );
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <DescriptionRenderer content={container.description} />
        </div>
      </div>
    </div>
  );
};
export default Description;
