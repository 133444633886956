import { useSearchParams } from "react-router-dom";
import Navbar from "../Navbar";

export default function ConfirmRegistration() {
  const [searchParams] = useSearchParams();
  const confirmed = searchParams.get("confirmed");
  return (
    <>
      <Navbar />
      <main>
        <div className="container pt-4">
          {confirmed === "true" ? (
            <>
              <h2>Thank you for registering!</h2>
              <p>Your account is now active. Use sign-in button to login</p>
              <a href="/login" className="btn btn-primary">
                Sign In
              </a>
            </>
          ) : (
            <>
              <h2>User Registration Confirmation:</h2>
              <div>
                To complete your registration, we've sent a confirmation link to
                the email address you provided during the registration process.
                Please check your inbox shortly.
              </div>
              <br />
              <h2>Important Information:</h2>
              <p>
                The confirmation link is valid for the next 48 hours. To ensure
                a smooth and secure registration process, please click on the
                link within this timeframe. If you don't see the email in your
                inbox, please check your spam or junk folder.
              </p>
              <p>
                If you encounter any issues or have questions, feel free to
                contact our support team at support@appulses.com. We're here to
                assist you.
              </p>
              <p>Best regards, Appulses Team</p>
            </>
          )}
        </div>
      </main>
    </>
  );
}
