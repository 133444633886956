import "./css/app.css";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./auth/Login";
import Auth from "./auth/Auth";
import { UserContext } from "./auth/UserContext";
import Contact from "./pages/contact/Contact";
import ConfirmRegistration from "./pages/registration/ConfirmRegistration";
import Terms from "./pages/terms/Terms";
import Policy from "./pages/terms/Policy";
import Pricing from "./pages/pricing/Pricing";
import { StoreContext } from "./api/StoreContext";
import { ApiClientContext } from "./api/ApiClientContext";
import ApiClient from "./api/ApiClient";

function App() {
  const location = useLocation();
  const [apiStore, setApiStore] = useState();
  const apiClient = ApiClient(location);

  useEffect(() => {
    if (!apiStore) {
      const fetchData = async () => {
        const apiStore = await apiClient.fetchData();
        return apiStore;
      };

      fetchData().then((store) => {
        setApiStore(store);
      });
    }
  }, [apiStore, apiClient]);
  return (
    <div>
      <ApiClientContext.Provider value={apiClient}>
        <StoreContext.Provider value={apiStore}>
          <UserContext.Provider value={Auth(apiStore)}>
            <Routes>
              <Route path="/" element={<Homepage />}></Route>
              <Route path="/dashboard" element={<Dashboard />}>
                <Route path="section/:sectionId" element={<Dashboard />} />
              </Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/terms" element={<Terms />}></Route>
              <Route path="/privacy-policy" element={<Policy />}></Route>
              <Route path="/pricing" element={<Pricing />}></Route>
              <Route
                path="/confirm-registration"
                element={<ConfirmRegistration />}
              ></Route>
            </Routes>
          </UserContext.Provider>
        </StoreContext.Provider>
      </ApiClientContext.Provider>
    </div>
  );
}

export default App;
