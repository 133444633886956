import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../auth/UserContext";
import Free from "./plans/Free";
import Basic from "./plans/Basic";
import Premium from "./plans/Premium";
import OneTimePayment from "./plans/OneTimePayment";
import PaidOptions from "./plans/PaidOptions";

const CurrentPlanView = ({ showPayment, setPlan }) => {
  const { getUserData } = useContext(UserContext);
  const [user, setUser] = useState({});
  useEffect(() => {
    if (getUserData) {
      setUser(getUserData);
    }
  }, [getUserData, user]);
  return (
    <>
      <div className="container">
        <div className="row">
          <h3>Current plan</h3>
        </div>
      </div>
      {user && user.paymentPlan === "NONE" ? (
        <div className="container">
          <div className="alert alert-danger" role="alert">
            Your trial period or active subscription has expired. Choose one of
            our paid options below to continue to have access to our platform.
          </div>
          <PaidOptions showPayment={showPayment} setPlan={setPlan} />
        </div>
      ) : null}

      {user && user.paymentPlan === "FREE" ? (
        <>
          <div className="container">
            <div className="row">
              <Free showPayment={showPayment} setPlan={setPlan} />
            </div>
            <div className="row mt-3">
              <PaidOptions showPayment={showPayment} setPlan={setPlan} />
            </div>
          </div>
        </>
      ) : null}

      {user && user.paymentPlan === "BASIC" ? (
        <Basic showPayment={showPayment} setPlan={setPlan} showUpgrade={true} />
      ) : null}

      {user && user.paymentPlan === "PREMIUM" ? (
        <Premium showPayment={showPayment} setPlan={setPlan} />
      ) : null}

      {user && user.paymentPlan === "ONE_TIME_PAYMENT" ? (
        <OneTimePayment showPayment={showPayment} setPlan={setPlan} />
      ) : null}
    </>
  );
};

export default CurrentPlanView;
