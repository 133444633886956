import logo from "../img/logo.jpg";

function Footer({ pageClassName }) {
  return (
    <footer className={pageClassName + " footer mt-auto py-3"}>
      <div className="container">
        <div className="row">
          <div className="col-3">
            <p className="mb-0 text-muted">2023 Appulses.com</p>
          </div>
          <div className="col-4">
            <a
              href="/"
              className="d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
            >
              <img alt="logo" src={logo} width="130" />
            </a>
          </div>
          <div className="col-5">
            <ul className="nav">
              <li className="nav-item">
                <a href="/" className="nav-link px-2 text-muted">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="/contact" className="nav-link px-2 text-muted">
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a href="/terms" className="nav-link px-2 text-muted">
                  Terms of use
                </a>
              </li>
              <li className="nav-item">
                <a href="/privacy-policy" className="nav-link px-2 text-muted">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
