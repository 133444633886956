import { useState } from "react";
import Basic from "./Basic";
import OneTimePayment from "./OneTimePayment";
import Premium from "./Premium";
const PaidOptions = ({ setPlan }) => {
  const [recurring, setRecurring] = useState(false);
  const [oneOff, setOneOff] = useState(false);
  const handleRecurring = (e) => {
    setRecurring(e.target.checked);
    setOneOff(!e.target.checked);
  };
  const handleOneOff = (e) => {
    setOneOff(e.target.checked);
    setRecurring(!e.target.checked);
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <h2>Select a payment type</h2>
          </div>
          <div className="col-6">
            <div className="form-check form-switch">
              <input
                checked={recurring}
                onChange={handleRecurring}
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckReccuring"
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckReccuring"
              >
                Recurring
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                checked={oneOff}
                onChange={handleOneOff}
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckOneOff"
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckOneOff"
              >
                One-off
              </label>
            </div>
          </div>
        </div>
        {recurring && (
          <>
            <div className="row ">
              <h3>Recurring payments</h3>
            </div>
            <div className="row">
              <div className="col col-6">
                <Basic showSubscribe={true} setPlan={setPlan} />
              </div>
              <div className="col col-6">
                <Premium showSubscribe={true} setPlan={setPlan} />
              </div>
            </div>
          </>
        )}
        {oneOff && (
          <>
            <div className="row ">
              <h3>One-off payment</h3>
            </div>
            <div className="row">
              <div className="col">
                <OneTimePayment showButton={true} setPlan={setPlan} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default PaidOptions;
