import React, { useState } from "react";
import ApiClient from "../../api/ApiClient";

function Section({ section }) {
  const [modalMode, setModalMode] = useState("edit");
  const apiClient = ApiClient();

  const saveSection = async (e, section) => {
    e.preventDefault();
    e.stopPropagation();

    const sectionName = document.getElementById(
      "section-name-" + section.id
    ).value;
    const sectionDescription = document.getElementById(
      "section-description-" + section.id
    ).value;

    console.log(sectionName);
    console.log(sectionDescription);

    if (
      sectionName === section.name &&
      sectionDescription === section.description
    ) {
      return;
    }
    const sectionUpdated = await apiClient.updateSection({
      id: section.id,
      name: sectionName,
      description: sectionDescription,
    });
    if (sectionUpdated) {
      window.location.reload();
    }
  };

  const deleteSection = async (section) => {
    console.log(section);

    const sectionNameInput = document.getElementById(
      `section-name-${section.id}`
    );
    if (sectionNameInput.value === section.name) {
      const sectionDeleted = await apiClient.deleteSection(section);
      if (sectionDeleted) {
        window.location.reload();
      }
    } else {
      sectionNameInput.classList.add("is-invalid");
    }
  };

  const openModal = (section, mode) => {
    setModalMode(mode);
    if (mode === "edit") {
      document.getElementById("section-description-" + section.id).value =
        section.description;
      document.getElementById("section-name-" + section.id).value =
        section.name;
    }
    window.$("#edit-section-modal-" + section.id).modal("show");
  };
  return (
    <div className="col-6">
      <div className="card mt-3">
        <div className="card-body">
          <h5 className="card-title">
            {section.name && section.name.length > 50
              ? section.name.substring(0, 50) + "..."
              : section.name}
          </h5>
          <p className="card-text">{section.description}</p>
          <a
            className="btn btn-light mb-3"
            href={`${apiClient.getDashboardPath()}/section/${section.id}`}
          >
            View
          </a>
          <button
            onClick={() => openModal(section, "edit")}
            className="btn btn-light mb-3"
          >
            Edit
          </button>
          <button
            onClick={() => openModal(section, "delete")}
            className="btn btn-light mb-3"
          >
            Delete
          </button>
        </div>
      </div>
      <div
        className="modal fade"
        id={"edit-section-modal-" + section.id}
        tabIndex="-1"
        aria-labelledby={"edit-section-label" + section.id}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id={"edit-section-label" + section.id}
              >
                Edit Section
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                {modalMode === "delete" && (
                  <div className="mb-3">
                    <label htmlFor="delete-section-name" className="form-label">
                      Please type <b>{section.name}</b> to delete section
                    </label>
                  </div>
                )}
                <div className="mb-3">
                  <label htmlFor="section-name" className="form-label">
                    Section Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id={"section-name-" + section.id}
                    defaultValue=""
                  />
                </div>
                {modalMode === "edit" && (
                  <div className="mb-3">
                    <label htmlFor="section-description" className="form-label">
                      Section Description
                    </label>
                    <textarea
                      className="form-control"
                      id={"section-description-" + section.id}
                      rows="3"
                      defaultValue={section.description}
                    ></textarea>
                  </div>
                )}
              </form>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => setModalMode("edit")}
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={(e) => {
                  modalMode === "edit"
                    ? saveSection(e, section)
                    : deleteSection(section);
                }}
                type="button"
                className="btn btn-light"
              >
                {modalMode === "edit" ? "Save changes" : "Delete"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
