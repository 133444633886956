import Navbar from "./Navbar";
import Footer from "./Footer";
import video from "../img/video.mp4";
import ReactGA from "react-ga4";

function Homepage() {
  return (
    <>
      <Navbar />
      <main>
        <div className="banner-lead position-relative overflow-hidden text-center">
          <div className="col-md-5 mx-auto my-5">
            <h1 className="display-4">Empower Your Infrastructure</h1>
            <p className="leads banner-lead-description">
              Streamlined Control and Comprehensive Information Integration..
            </p>
            <a className="btn btn-primary" href="/login">
              Start free trial
            </a>
            <a
              className="btn btn-danger"
              href="#video"
              onClick={() => {
                ReactGA.event({
                  name: "select_content",
                  action: "click",
                  category: "Show video",
                });
                document.getElementById("promo-video").scrollIntoView();
                document.getElementById("promo-video").play();
              }}
            >
              Watch Video
            </a>
          </div>
        </div>

        <div className="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
          <div className="bg-light me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
            <div className="my-3 p-3 knowledge-section">
              <div className="container mb-5">
                <div className="row">
                  <div className="col-6">
                    <h2 className="display-4 homepage-list-header">
                      Effortlessly map relationships
                    </h2>
                    <ul className="homepage-list">
                      <li>
                        <p>
                          Automatically create a map of your microservices and
                          the connections between them
                        </p>
                      </li>
                      <li>
                        <p>
                          Implement advanced access controls for knowledge
                          sharing
                        </p>
                      </li>
                      <li>
                        <p>
                          Ensure clarity with documentation aligned to the
                          trusted C4 model
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="homepage-image homepage-image-green"></div>
                  </div>
                </div>
                <div className="row mt-5 mb-5"></div>
                <div className="row">
                  <div className="col-5">
                    <div className="homepage-image homepage-image-grey"></div>
                  </div>
                  <div className="col-1"></div>
                  <div className="col-6">
                    <h2 className="display-5 homepage-list-header">
                      What do I need it for?
                    </h2>
                    <ul className="homepage-list">
                      <li>
                        <p>IT infrastructure has grown and is scattered?</p>
                      </li>
                      <li>
                        <p>Onbording takes many months?</p>
                      </li>
                      <li>
                        <p>
                          Documentation is inconsistent, outdated and hard to
                          find?
                        </p>
                      </li>
                      <li>
                        <p>
                          Do you want to have control over who has access to the
                          system's documentation and grant appropriate
                          permissions?
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5 pt-5">
          <video width="100%" height="100%" controls id="promo-video">
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </main>
      <Footer pageClassName={"homepage-footer"} />
    </>
  );
}

export default Homepage;
