import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../auth/UserContext";
import Subscriptions from "./subscriptions/Subscriptions";
import ApiMainView from "./api/ApiMainView";
import Permissions from "./permissions/Permissions";
export default function AccountModal() {
  const { getUserData } = useContext(UserContext);
  const [user, setUser] = useState({});
  useEffect(() => {
    if (getUserData && getUserData.email) {
      setUser(getUserData);
    }
  }, [getUserData, user]);
  return (
    <div className="modal" tabIndex="-1" id="accountMenuModal">
      <div className="modal-dialog  modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="accountMenuModalLabel">
              Account settings
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-3">
                <div
                  className="nav flex-column nav-pills me-3"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link active"
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="true"
                  >
                    Profile
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-subscription-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-subscription"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-subscription"
                    aria-selected="true"
                  >
                    Subscription
                  </button>
                  <button
                    className={
                      user.paymentPlan === "PREMIUM" ||
                      user.paymentPlan === "ONE_TIME_PAYMENT"
                        ? "nav-link"
                        : "nav-link disabled"
                    }
                    id="v-pills-api-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-api"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-api"
                    aria-selected="true"
                  >
                    API
                  </button>
                  <button
                    className={
                      user.paymentPlan === "PREMIUM" ||
                      user.paymentPlan === "ONE_TIME_PAYMENT"
                        ? "nav-link"
                        : "nav-link disabled"
                    }
                    id="v-pills-permissions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-permissions"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-permissions"
                    aria-selected="true"
                  >
                    Permissions
                  </button>
                </div>
              </div>
              <div className="col-9">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <h2>Profile</h2>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <b>email : </b> <span>{user.email}</span>
                      </li>
                      <li className="list-group-item">
                        <b>account type : </b>
                        {user.accountType ? user.accountType : "root"}
                        <span>{user.accountType}</span>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-subscription"
                    role="tabpanel"
                    aria-labelledby="v-pills-subscription-tab"
                  >
                    <Subscriptions />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-api"
                    role="tabpanel"
                    aria-labelledby="v-pills-api-tab"
                  >
                    <ApiMainView />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-permissions"
                    role="tabpanel"
                    aria-labelledby="v-pills-permissions-tab"
                  >
                    <Permissions />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
