const Free = ({ showPayment, setPlan }) => {
  return (
    <div className="col">
      <div className="card">
        <div className="card-header">FREE 7 days trial</div>
        <div className="card-body">
          <div className="card-title">
            <span className="plan-price">
              <b>0$</b>
            </span>{" "}
          </div>
          <ul className="list-group">
            <li className="list-group-item">
              sections{" "}
              <span className="badge bg-primary rounded-pill float-end">1</span>
            </li>
            <li className="list-group-item">
              accounts{" "}
              <span className="badge bg-primary rounded-pill float-end">1</span>
            </li>
            <li className="list-group-item">
              containers{" "}
              <span className="badge bg-primary rounded-pill float-end">
                10
              </span>
            </li>
            <li className="list-group-item">
              API{" "}
              <span className="badge bg-danger rounded-pill float-end">
                <i className="bi bi-x-lg"></i>
              </span>
            </li>
            <li className="list-group-item">
              Permissions{" "}
              <span className="badge bg-danger rounded-pill float-end">
                <i className="bi bi-x-lg"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Free;
