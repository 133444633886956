import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import DetailsModal from "./DetailsModal";
import Menu from "../menu/Menu";
import NewContainerModal from "./NewContainerModal";
import DashboardContent from "./DashboardContent";
import SectionsList from "./SectionsList";
import NewSectionModal from "./NewSectionModal";
import AccountModal from "../account/AccountModal";
import { StoreContext } from "../../api/StoreContext";
import ApiClient from "../../api/ApiClient";

function Dashboard() {
  const [links, setLinks] = useState([]);
  const [sections, setSections] = useState();
  const apiStore = useContext(StoreContext);
  const apiClient = ApiClient();
  const [currentModalContainer, setCurrentModalContainer] = useState({});
  let { sectionId } = useParams();
  useEffect(() => {
    if (apiStore && apiStore.sections && apiStore.sections.length > 0) {
      setSections(apiStore.sections);
    }
  }, [sections, apiStore, currentModalContainer]);

  const updateRequest = async (payload) => {
    return await apiClient.updateContainer(payload);
  };

  const saveNodeName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var currentNodeName = window.$("#currentNodeName").val();
    var currentNodeId = window.$("#currentNodeId").val();

    updateRequest({
      name: currentNodeName,
      method: "PATCH",
      containerId: currentNodeId,
      nameChanged: true,
    }).then((result) => {
      window.location.reload(true);
    });
  };

  async function viewContainer(id) {
    const element = await apiClient.getNodeById(id);
    window.$("#currentNodeId").val(id);
    window.$("#currentNodeName").val(element.name);
    window.$("#containerModal").modal("show");
    setCurrentModalContainer(element);
    window.$('a[data-toggle="pill"]').on("shown.bs.tab", function (e) {
      const currentTab = e.target.id;
      if (currentTab === "v-pills-relations-tab") {
        //on relations tab
      }
    });
  }

  const createContainer = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const containerNameInput = window.$("#containerName");
    const containerDescriptionInput = window.$("#containerDescription");
    containerNameInput.removeClass("is-invalid was-validated");
    if (containerNameInput.val() === null || containerNameInput.val() === "") {
      window.$("#containerName").addClass("is-invalid was-validated");
      return;
    }
    const menuWidth = document
      .getElementById("main-menu")
      .getBoundingClientRect().width;
    const topPosition =
      document.getElementById("drawboardContent").getBoundingClientRect().top +
      10;
    const leftPosition =
      document.getElementById("drawboardContent").getBoundingClientRect().left +
      menuWidth +
      10;

    window.$("#newContainerModal").modal("hide");

    const payload = {
      top: topPosition,
      left: leftPosition,
      name: containerNameInput.val(),
      description: containerDescriptionInput.val(),
      sectionId: sectionId,
    };

    await apiClient.createContainer(payload);
    await apiClient.fetchContainers(sectionId);
  };

  const createSection = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const sectionNameInput = window.$("#sectionName");
    const sectionDescriptionInput = window.$("#sectionDescription");
    sectionNameInput.removeClass("is-invalid was-validated");
    if (sectionNameInput.val() === null || sectionNameInput.val() === "") {
      window.$("#areaName").addClass("is-invalid was-validated");
      return;
    }

    window.$("#newAreaModal").modal("hide");
    const sectionCreated = apiClient.createSection({
      name: sectionNameInput.val(),
      description: sectionDescriptionInput.val(),
    });
    if (sectionCreated) {
      window.location.reload(true);
    }
  };

  const newNodeAction = () => {
    window.$("#newContainerModal").modal("show");
    window.$("#containerName").val("");
    window.$("#containerName").removeClass("is-invalid was-validated");
  };
  const newAreaAction = () => {
    window.$("#newAreaModal").modal("show");
    window.$("#areaName").val("");
    window.$("#areaName").removeClass("is-invalid was-validated");
  };

  const dashboardContent = () => {
    return (
      <DashboardContent
        setCurrentModalContainer={setCurrentModalContainer}
        apiClient={apiClient}
        links={links}
        setLinks={setLinks}
      />
    );
  };

  const sectionsList = () => {
    return <SectionsList />;
  };

  return (
    <div className="drawboard">
      <div className="drawboard-wrapper">
        <Menu
          newNodeAction={newNodeAction}
          newAreaAction={newAreaAction}
          sections={sections}
          sectionId={sectionId}
        />
        {sectionId ? dashboardContent() : null}
        {!sectionId ? sectionsList() : null}
      </div>
      <DetailsModal
        viewContainer={viewContainer}
        container={currentModalContainer}
        saveNodeName={saveNodeName}
      />
      <NewContainerModal createContainer={createContainer} />
      <NewSectionModal createSection={createSection} />
      <AccountModal />
    </div>
  );
}
export default Dashboard;
