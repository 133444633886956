import { useState, useRef } from "react";
import ApiUploadSuccess from "./ApiUploadSuccess";
function ApiUploadForm({ containerId, uploadPath }) {
  const [dragActive, setDragActive] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [uploadfailed, setUploadFailed] = useState(false);
  const inputRef = useRef(null);

  const upload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const path = uploadPath ? uploadPath : `/apiDefinition`;
    const response = await fetch(`/api/containers/${containerId}/${path}`, {
      method: "POST",
      body: formData,
    });
    if (response.ok) {
      setUploaded(true);
    } else {
      setUploadFailed(true);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      upload(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      upload(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const uploadForm = () => {
    return (
      <form
        id="form-file-upload"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={true}
          onChange={handleChange}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? "drag-active" : ""}
        >
          <div>
            <p>Drag and drop your file here or</p>
            <button className="btn btn-primary" onClick={onButtonClick}>
              Browse
            </button>
          </div>
        </label>
      </form>
    );
  };
  return (
    <div>
      {uploadfailed ? (
        <div className="alert alert-danger">Upload failed</div>
      ) : (
        ""
      )}
      {uploaded ? <ApiUploadSuccess /> : uploadForm()}
    </div>
  );
}

export default ApiUploadForm;
