import { RedocStandalone } from "redoc";
import ApiClient from "../../api/ApiClient";

function ApiDocumentationView({ container }) {
  const apiClient = ApiClient();
  const specUrl = apiClient.getApiSpecPath(container.id);
  return (
    <div className="documentation-view">
      <RedocStandalone specUrl={specUrl} />
    </div>
  );
}

export default ApiDocumentationView;
