import ApiDocumentationView from './ApiDocumentationView'
import ApiUploadForm from './ApiUploadForm'

function ApiDocumentation({ container }) {
    const containerId = container.id;
    return (
        <div id="api-documentation-view">
            {   
                container.specFileUploaded
                ? <ApiDocumentationView container={container}/>
                : <ApiUploadForm containerId={containerId} />
            }
        </div>
    )
}

export default ApiDocumentation;