import React, { useEffect, useState } from "react";
import dropin from "braintree-web-drop-in";

const Payment = ({ showPayment, plan }) => {
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const className = `alert ${error ? "alert-danger" : ""} ${
    success ? "alert-success" : ""
  }`;
  useEffect(() => {
    if (showPayment) {
      const initializeBraintree = () =>
        dropin.create(
          {
            authorization: "sandbox_fw67ngcc_4bfx8vw8mmf37666",
            container: "#braintree-drop-in-div",
            paypal: {
              flow: "vault",
            },
          },
          (error, instance) => {
            if (error) console.error(error);
            else setBraintreeInstance(instance);
          }
        );

      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
    // eslint-disable-next-line
  }, [showPayment]);

  const requestPaymentMethod = () => {
    if (braintreeInstance) {
      braintreeInstance.requestPaymentMethod(async (error, payload) => {
        if (error) {
          setError("There was an error processing the payment");
        } else {
          const nonce = payload.nonce;
          await fetch("/api/payment", {
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({ nonce, plan }),
          })
            .then((response) => {
              if (response.ok) {
                setSuccess("Payment successful");
              } else {
                setError("There was an error processing the payment");
              }
            })
            .catch(() => {
              setError("There was an error processing the payment");
            });
        }
      });
    }
  };

  return (
    <div className="container">
      <div id="message" className={className}>
        {error || success}
      </div>
      <h3>Payment methods</h3>
      <div id="braintree-drop-in-div"></div>
      <button className="btn btn-primary" onClick={requestPaymentMethod}>
        Pay now
      </button>
      <button className="btn btn-primary" onClick={() => showPayment(false)}>
        Back
      </button>
    </div>
  );
};

export default Payment;
