const OnPremise = ({ showPayment, setPlan }) => {
  return (
    <div className="col">
      <div className="card">
        <div className="card-header">On-premises solution</div>
        <div className="card-body">
          <div className="card-title"></div>
          <ul className="list-group">
            <li className="list-group-item">
              <p>
                We offer the possibility of installation on the customer's GCP,
                Azure or AWS infrastructure.
              </p>
              <p>Contact us for more details.</p>
            </li>
          </ul>
          <a href="/contact" className="btn btn-primary mt-3">
            Contact us
          </a>
        </div>
      </div>
    </div>
  );
};

export default OnPremise;
