import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../auth/UserContext";
import { StoreContext } from "../../api/StoreContext";

export default function AccountMenu() {
  const auth = useContext(UserContext);
  const apiStore = useContext(StoreContext);

  const [userData, setUserData] = useState({});
  const openModal = () => {
    window.$("#accountMenuModal").modal("show");
  };
  useEffect(() => {
    if (apiStore && apiStore.userData) {
      setUserData(apiStore.userData);
    }
  }, [apiStore]);
  return (
    <>
      <div className="account-menu container mb-1">
        <div className="row align-items-center pt-2 pb-2 px-2">
          <div className="col-2">
            <i className="bi bi-person-circle"></i>
          </div>
          <div className="col-10 user-email">{userData.email}</div>
        </div>
        <div className="row justify-content-start pt-2 pb-2">
          <div className="col-3">
            <button
              onClick={() => openModal()}
              className="btn btn-light btn-sm"
            >
              Settings
            </button>
          </div>
          <div className="col-3">
            <button
              className="btn btn-light btn-sm ml-3"
              onClick={() => auth.logout()}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
