import Navbar from "../Navbar";
import Footer from "../Footer";
export default function Policy() {
  return (
    <>
      <Navbar />
      <div className="container pt-4">
        <p>
          <strong>PRIVACY POLICY</strong>
        </p>
        <p>
          In this privacy policy we, Appulses, with our registered office
          Taczaka 24, 61-819 Poznań, Poland, and our website (“Appulses.com”),
          wish to explain how we handle personal data of our Users when they
          visit our website and use our services.
        </p>
        <p>
          All the terms used in Appulses.com Terms &amp; Conditions have the
          same meaning as in this Privacy Policy. When you first visit
          Appulses.com site, you will be asked to consent to our use of cookies
          in accordance with these terms.
        </p>
        <p>
          We incorporate such privacy controls, that will provide you with
          controls on deciding how we will process your personal data. Use
          privacy controls, with which you can specify whether you would like to
          receive direct marketing communications from Appulses.com.
        </p>
        <p>
          Please let us know if the personal information that we hold about you
          needs to be corrected or updated.
        </p>
        <p>
          If you wish to exercise any of your rights provided in this Privacy
          Policy or contact us regarding all privacy-related issues, you may
          submit or request by email:{" "}
          <a href="mailto:support@appulses.com,">support@appulses.com</a>.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <strong>
            I How is our site using your data and how long are we storing them?
          </strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            We process data about your use of our website and services&nbsp;(“
            <strong>
              <em>usage data</em>
            </strong>
            ”). The usage data may include your IP address, geographical
            location, browser type and version, operating system, referral
            source, length of visit, page views and website navigation paths, as
            well as information about the timing, frequency and pattern of your
            service use. We obtain such data through the use of cookies and
            similar technologies.
          </li>
          <li>
            We process usage data to have a better understanding of how you use
            our website and services. The legal basis for this processing is our
            legitimate interest, namely monitoring and improving our website and
            services and accommodating the services for individual interests of
            every User.
          </li>
          <li>
            We process your account data&nbsp;(“<strong>account data</strong>
            ”). The account data may include your name and email address, phone
            number and other data that you provide while registering as well as
            your purchase history. We obtain such data directly from you.
          </li>
          <li>
            We process account data for the purposes of operating our website,
            providing our services, ensuring the security of our website and
            services and communicating with you as our User. The legal basis for
            this processing is the performance of a contract between you and us
            and/or taking steps, at your request, to enter into such a contract
            as well as our legitimate interest, namely monitoring and improving
            our website and services.
          </li>
          <li>
            We process information relating to provision of services by us to
            you in our website&nbsp;(“<strong>transaction data</strong>”). The
            transaction data may include your contact details, bank account
            details and the transaction details. The transaction data is
            processed to provide services and keep proper records of those
            transactions. The legal basis for this processing is the
            performances of a contract between you and us and/or taking steps,
            at your request, to enter into such a contract and our legitimate
            interests, namely the proper administration of our website and
            business.
          </li>
          <li>
            We may process information that you provide to us for the purpose of
            subscribing to our email messages and newsletters&nbsp;(“
            <strong>messaging data</strong>”). The messaging data is processed
            to send you relevant messages and newsletters. The legal basis for
            this processing is your consent. Also, if you are a User and you do
            not object, we may also process messaging data on the basis of our
            legitimate interest, namely seeking to maintain and improve customer
            relations.
          </li>
          <li>
            We may process information relating to any communication that you
            send to us&nbsp;(“<strong>correspondence data</strong>”). The
            correspondence data may include the communication content and
            metadata associated with the communication. In case of communication
            through our website, the website will generate the metadata
            associated with communications made using the website contact forms.
            The correspondence data is processed for the purposes of
            communicating with you and record-keeping. The legal basis for this
            processing is our legitimate interests, namely the proper
            administration of our website and business, ensuring uniform and
            high-quality consultation practice
          </li>
          <li>
            We may process any of your personal data identified in this notice
            where necessary for the establishment, exercise or defense of legal
            claims, whether in court proceedings or in an administrative or
            out-of-court procedure. The legal basis for this processing is our
            legitimate interests, namely the protection and assertion of our
            legal rights and your legal rights.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <strong>II How long are we storing your data?</strong>
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            Your personal data that we process for any purpose or purposes shall
            not be kept for longer than is necessary for that purpose or those
            purposes. In any case it shall be kept for no longer than:
          </li>
          <li>
            <strong>usage data</strong> will be retained for as much as will be
            necessary for the relevant processing purposes.
          </li>
          <li>
            <strong>account data</strong> will be retained for no longer than{" "}
            <strong>5 (five) years</strong> following your last update on the
            account;
          </li>
          <li>
            <strong>transaction data</strong> will be retained for no longer
            than <strong>10 (ten) years</strong> following the end of the
            provision of services;
          </li>
          <li>
            <strong>messaging data </strong>will be retained for as long as your
            account is active unless you withdraw your consent earlier;
          </li>
          <li>
            <strong>correspondence data </strong>will be retained for no longer
            than <strong>6 (six) months </strong>following the end of such
            communication.
          </li>
          <li>
            Notwithstanding the other provisions of this Section, we may retain
            your personal data where such retention is necessary for compliance
            with a legal obligation to which we are subject, or in order to
            protect your vital interests.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <strong>III When will we provide your data to others? </strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol>
          <li>
            We may disclose your personal data&nbsp;to our{" "}
            <strong>payment service providers</strong>. We will share
            transaction data with our payment services providers only to the
            extent necessary for the purposes of processing your payments,
            transferring funds and dealing with complaints and queries relating
            to such payments and transfers.
          </li>
          <li>
            We may disclose your personal data to another service
            <strong> providers</strong>&nbsp;as it is reasonably necessary to
            provide specific services (including, providers of servers and
            maintenance thereof, email service providers). We take all the
            necessary measures to ensure that such subcontractors would
            implement proper measures to ensure security and privacy of your
            personal data.
          </li>
          <li>
            In addition to the specific disclosures of personal data set out in
            this Section, we may disclose your personal data where such
            disclosure is{" "}
            <strong>necessary for compliance with a legal obligation</strong> to
            which we are subject, or in order to protect your vital interests.
          </li>
          <li>
            Persons, indicated in this Section may be established{" "}
            <strong>outside European Union and European Economic Area</strong>.
            In case we will transfer your personal data to such persons, we will
            take all the necessary and in the legal acts indicated measures to
            ensure that your privacy will remain properly secured, including
            where appropriate, signing standard contractual clauses for transfer
            of data.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <strong>IV What is our marketing policy? </strong>
        </p>
        <ol>
          <li>
            <ol>
              <li>
                In case your consent, we will be allowed to send you marketing
                messages via email and/or leave a notification in an Account to
                inform you on what we are up to.
              </li>
              <li>
                Also, if we already have provided services to you and you do not
                object we will inform you about our other products that might
                interest you including other information related to such.
              </li>
              <li>
                You may opt-out of receiving marketing messages at any time, by:
                <ol>
                  <li>
                    choosing the relevant link in any of our marketing messages;
                  </li>
                  <li>
                    contacting us via email{" "}
                    <a href="mailto:support@appulses.com,">
                      support@appulses.com,
                    </a>
                  </li>
                </ol>
              </li>
              <li>
                The opt-out of the marketing messages will not stop you from
                receiving messages directly related to the provision of services
                set in the Terms &amp; Conditions.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <strong>V What are your rights related to personal data? </strong>
        </p>
        <ol>
          <li>
            <ol>
              <li>
                Your principal rights under data protection law are as follows
                in the Sections ahead.
              </li>
              <li>
                <strong>The</strong> <strong>right to access data</strong> - you
                have the right to confirmation as to whether or not we process
                your personal data and, where we do, access to the personal
                data, together with certain additional information.
              </li>
              <li>
                <strong>The right to rectification</strong> - you have the right
                to have any inaccurate personal data about you rectified and,
                taking into account the purposes of the processing, to have any
                incomplete personal data about you completed. Please notice that
                you may exercise most of this right by logging into your account
                and changing the data.
              </li>
              <li>
                <strong>The right to the erasure of your personal data</strong>{" "}
                – this applies in some circumstances only. Those circumstances
                include when: (I) the personal data are no longer necessary in
                relation to the purposes for which they were collected or
                otherwise processed; (ii) you withdraw consent to consent-based
                processing and there are no other legal basis to process data;
                (iii) you object to the processing under certain rules of
                applicable data protection laws; (iv) the processing is for
                direct marketing purposes; or (v) the personal data have been
                unlawfully processed. However, there are exclusions of the right
                to erasure. Such exclusions include when processing is
                necessary: (i)&nbsp;for exercising the right of freedom of
                expression and information; (ii)&nbsp;for compliance with our
                legal obligation; or (iii) for the establishment, exercise or
                defense of legal claims.
              </li>
              <li>
                In some circumstances, you have the{" "}
                <strong>right to restrict the processing</strong> of your
                personal data. Those circumstances are when: (i) you contest the
                accuracy of the personal data; (ii) processing is unlawful but
                you oppose erasure; (iii) we no longer need the personal data
                for the purposes of our processing, but you require personal
                data for the establishment, exercise or defense of legal claims;
                and (iv) you have objected to processing, pending the
                verification of that objection. Where processing has been
                restricted on this basis, we may continue to store your personal
                data, however, we will only further process such data in any
                other way: (i) with your consent; (ii) for the establishment,
                exercise or defense of legal claims; (iii) for the protection of
                the rights of another person; or (iv) for reasons of important
                public interest.
              </li>
              <li>
                You have the <strong>right to object to our processing</strong>{" "}
                of your personal data&nbsp;on grounds relating to your
                particular situation, but only to the extent that the legal
                basis for the processing is that the processing is necessary
                for: the performance of a task carried out in the public
                interest or the purposes of the legitimate interests pursued by
                us or by a third party.
              </li>
              <li>
                You have the right to object to our processing of your personal
                data for direct marketing purposes&nbsp;(including profiling for
                direct marketing purposes).
              </li>
              <li>
                You have the <strong>right to object to our processing</strong>{" "}
                of your personal data for scientific or historical research
                purposes or statistical purposes on grounds relating to your
                particular situation unless the processing is necessary for the
                performance of a task carried out for reasons of public
                interest.
              </li>
              <li>
                <strong>The right to data portability.</strong> To the extent
                that the legal basis for our processing of your personal data
                is:
                <ol>
                  <li>consent; or</li>
                  <li>
                    performance of a contract or steps to be taken at your
                    request prior to entering into a contract, necessary to
                    enter into such,&nbsp;you have the right to receive your
                    personal data from us in a structured, commonly used and
                    machine-readable format. However, this right does not apply
                    where it would adversely affect the rights and freedoms of
                    others.
                  </li>
                  <li>
                    To the extent that the legal basis for our processing of
                    your personal information is consent, you have the right to
                    withdraw that consent at any time. Withdrawal will not
                    affect the lawfulness of processing before the withdrawal.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <strong>VI Cookies</strong>
        </p>
        <ol>
          <li>
            <ol>
              <li>
                Cookies are small text files containing an identifier that is
                sent by a web server to your web browser and is stored by the
                browser. The identifier is then sent back to the server each
                time the browser requests a page from the server.
              </li>
              <li>
                Cookies do not typically contain any information that personally
                identifies a user, but personal information that we store about
                you may be linked to the information stored in and obtained from
                cookies.
              </li>
              <li>
                In the website we use cookies of three main types, for the
                following purposes:
                <ol>
                  <li>
                    Required cookies – used to ensure proper performance of the
                    website, security of customers and their data, provision of
                    high-quality services and effortless set-up of an account;
                  </li>
                  <li>
                    Functional cookies – used to enhance the website user
                    experience, analyze the use of the system and in accordance
                    to such improve the provision of services;
                  </li>
                  <li>
                    Advertising cookies – used to observe user online behavior
                    and optimize marketing campaigns according to such
                    information.
                  </li>
                </ol>
              </li>
              <li>
                Most browsers allow you to refuse to accept cookies and to
                delete cookies. The methods for doing so vary from browser to
                browser, and from version to version. Blocking all cookies will
                have a negative impact upon the usability of many websites.
              </li>
              <li>
                If you block cookies, you will not be able to use all the
                features on our website.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <Footer />
    </>
  );
}
