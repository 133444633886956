import { useContext, useEffect } from "react";
import ApiClient from "../../api/ApiClient";
import { StoreContext } from "../../api/StoreContext";

const ApiRelations = ({ container, viewContainer }) => {
  const apiClient = ApiClient();
  const apiStore = useContext(StoreContext);
  useEffect(() => {}, [apiStore]);
  const getContainerById = (containerId) => {
    return apiStore.containers.find((c) => c.id === containerId);
  };
  const getContainerName = (containerId) => {
    const linkedContainer = getContainerById(containerId);
    return linkedContainer.name;
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <h3>Inbound requests</h3>
          </div>
          <div className="col">
            <h3>Outbound requests</h3>
          </div>
        </div>
        <div className="row">
          <div className="col inbound-relations" id="inbound-relations">
            {container &&
              container.inboundLinks &&
              container.inboundLinks.map((link) => (
                <div
                  className="relation-node"
                  id={`relation-node-${link.startFrom}`}
                  key={`relation-node-${link.startFrom}`}
                >
                  <button
                    className="btn"
                    onClick={async () => viewContainer(link.startFrom)}
                  >
                    {getContainerName(link.startFrom)}
                  </button>
                  <div className="relation-node-delete">
                    <button
                      className="btn"
                      onClick={async () => {
                        const deleted = await apiClient.deleteLinkById(link.id);
                        console.log("delete relation " + deleted);
                      }}
                    >
                      <span aria-hidden="true">&#128465;</span>
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="col outbond-relations" id="outbond-relations">
            {container &&
              container.outboundLinks &&
              container.outboundLinks.map((link) => (
                <div
                  className="relation-node"
                  id={`relation-node-${link.endAt}`}
                  key={`relation-node-${link.endAt}`}
                >
                  <div>
                    <button
                      className="btn"
                      onClick={async () => viewContainer(link.endAt)}
                    >
                      {getContainerName(link.endAt)}
                    </button>
                  </div>
                  <div className="relation-node-delete">
                    <button
                      className="btn"
                      onClick={async () => {
                        const deleted = await apiClient.deleteLinkById(link.id);
                        console.log("delete relation " + deleted);
                      }}
                    >
                      <span aria-hidden="true">&#128465;</span>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiRelations;
