import { useState, useEffect, useContext } from "react";
import ContainerListRenderer from "./ContainerListRenderer";
import { StoreContext } from "../../api/StoreContext";

const LeaderLine = window.LeaderLine;
const PlainDraggable = window.PlainDraggable;

function DashboardContent({ setCurrentModalContainer, links, setLinks }) {
  const apiStore = useContext(StoreContext);
  const [containersHttpResponse, setContainersHttpResponse] = useState([]);

  useEffect(() => {
    if (apiStore && apiStore.containers && apiStore.containers.length > 0) {
      setContainersHttpResponse(apiStore.containers);
    }
    if (links && links.length > 0) {
      const draggablePlaneItem = document.getElementById("drawboardContent");
      const draggablePlane = new PlainDraggable(draggablePlaneItem, {
        onMove: function (newPosition) {
          if (links.length > 0) {
            links.map((link) => link.line.position());
          }
        },
      });
      draggablePlane.autoScroll = true;
    }
  }, [apiStore, containersHttpResponse, links]);

  function newLine(startNode, handle) {
    return new LeaderLine(startNode, handle, {
      color: "black",
      size: 2,
      path: "grid",
    });
  }

  const renderLinks = async () => {
    links.map((link) => link.line.remove());
    setLinks([]);
    containersHttpResponse.forEach((container) => {
      if (Array.isArray(container.outboundLinks)) {
        container.outboundLinks.forEach((outboundLink) => {
          var lineCreated = newLine(
            document.getElementById(container.id),
            document.getElementById(outboundLink.endAt)
          );
          links.push({
            startNode: container.id,
            endNode: outboundLink.endAt,
            line: lineCreated,
          });
        });
      }
    });
  };

  window.$(window).on("resize", () => {
    links.map((link) => link.line.position().show());
  });

  return (
    <div id="drawbordScroll" className="drawbord-scroll">
      <div className="drawbord-content" id="drawboardContent">
        {containersHttpResponse && containersHttpResponse.length > 0 && (
          <ContainerListRenderer
            containersHttpResponse={containersHttpResponse}
            setCurrentModalContainer={setCurrentModalContainer}
            links={links}
            renderLinks={renderLinks}
          />
        )}
      </div>
    </div>
  );
}

export default DashboardContent;
