import Navbar from "../Navbar";
import Footer from "../Footer";

export default function Terms() {
  return (
    <>
      <Navbar />
      <div className="container pt-4">
        <h2>Terms of Use for Appulses.com</h2>
        <p>[2024-01-01]</p>
        <p>
          Welcome to Appulses.com! These Terms of Use ("Terms") govern your use
          of our SaaS (Software as a Service) application provided by
          Appulses.com ("we," "us," or "our"). By accessing or using our
          services, you agree to be bound by these Terms. If you do not agree to
          these Terms, please do not use our services.
        </p>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By using Appulses.com, you acknowledge that you have read, understood,
          and agree to be bound by these Terms. We reserve the right to modify
          or update these Terms at any time without notice. It is your
          responsibility to review these Terms periodically for changes. Your
          continued use of the services after any modifications constitutes
          acceptance of the updated Terms.
        </p>
        <h2>2. Subscription and Payments</h2>
        <p>
          Appulses.com operates on a subscription-based model. By subscribing to
          our services, you agree to pay the applicable fees as outlined in our
          pricing plans. Payments are due in advance of the subscription period
          and are non-refundable. Failure to pay may result in the suspension or
          termination of your account.
        </p>
        <h2>3. Account Registration and Security</h2>
        <p>
          To access certain features of Appulses.com, you may be required to
          register for an account. You agree to provide accurate, current, and
          complete information during the registration process and to update
          such information to keep it accurate, current, and complete. You are
          responsible for maintaining the confidentiality of your account
          credentials and for all activities that occur under your account.
        </p>
        <h2>4. Use of the Service</h2>
        <p>
          You agree to use Appulses.com in compliance with all applicable laws
          and regulations. You may not use the service for any unlawful or
          prohibited purpose. You are solely responsible for the content you
          create, upload, or share through the service.
        </p>
        <h2>5. Intellectual Property</h2>
        <p>
          All content, features, and functionality provided by Appulses.com are
          the exclusive property of Appulses.com and are protected by copyright,
          trademark, and other intellectual property laws. You may not
          reproduce, distribute, modify, or create derivative works of any part
          of our services without our prior written consent.
        </p>
        <h2>6. Privacy</h2>
        <p>
          Our Privacy Policy governs the collection, use, and disclosure of your
          personal information. By using Appulses.com, you consent to the
          practices outlined in our Privacy Policy.
        </p>
        <h2>7. Termination</h2>
        <p>
          We reserve the right to suspend or terminate your access to
          Appulses.com at any time for any reason, without notice. Upon
          termination, your right to use the services will immediately cease.
        </p>
        <h2>8. Disclaimer of Warranties</h2>
        <p>
          Appulses.com is provided "as is" without any warranties, express or
          implied. We do not warrant that the service will be uninterrupted,
          error-free, or free of harmful components.
        </p>
        <h2>9. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, Appulses.com shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues.
        </p>
        <h2>10. Governing Law</h2>
        <p>
          These Terms are governed by and construed in accordance with the laws
          of Poland, without regard to its conflict of law principles.
        </p>
        <h2>Contact Information</h2>
        <p>
          If you have any questions about these Terms, please contact us at{" "}
          <a href="mailto:support@appulses.com">support@appulses.com</a>.
        </p>
      </div>
      <Footer />
    </>
  );
}
