import { createElement, useEffect, useState, createRef } from "react";
import Container from "./Container";
import ApiClient from "../../api/ApiClient";

const LeaderLine = window.LeaderLine;
const PlainDraggable = window.PlainDraggable;

const ContainerListRenderer = ({
  containersHttpResponse,
  setCurrentModalContainer,
  links,
  renderLinks,
}) => {
  const [draggableNodes, setDraggableNodes] = useState({});
  const [containers, setContainers] = useState({});
  const [readyCount, setReadyCount] = useState(0);
  const [dragging, setDragging] = useState(false);
  const currentMousePosition = { x: 0, y: 0, width: 1, height: 1 };
  const apiClient = ApiClient();

  function findInboundLinks(id) {
    return links.filter((l) => l.endNode === id);
  }

  function findOutBondLinks(id) {
    return links.filter((l) => l.startNode === id);
  }

  function findLinks(id) {
    return findOutBondLinks(id).concat(findInboundLinks(id));
  }

  async function getNodeById(id) {
    return apiClient.getNodeById(id);
  }

  async function viewContainer(id) {
    const element = await getNodeById(id);
    window.$("#currentNodeId").val(id);
    window.$("#currentNodeName").val(element.name);
    window.$("#containerModal").modal("show");
    setCurrentModalContainer(element);
    window.$('a[data-toggle="pill"]').on("shown.bs.tab", function (e) {
      const currentTab = e.target.id;
      if (currentTab === "v-pills-relations-tab") {
        //on relations tab
      }
    });
  }

  async function deleteContainer(id) {
    const deleted = await apiClient.deleteById(id);
    if (deleted) {
      window.location.reload(true);
    }
  }

  async function updateById(id, updateRequest) {
    return apiClient.updateContainerById(id, updateRequest);
  }

  function findNodeToLink(startNode) {
    let element;
    Object.keys(draggableNodes).forEach((elementKey) => {
      const item = document.getElementById(elementKey);
      const rect = item.getBoundingClientRect();
      if (
        isCollide(rect, currentMousePosition) &&
        elementKey !== startNode.id
      ) {
        item.classList.toggle("node-hover");
        element = item;
      }
    });
    return element;
  }

  function newLine(startNode, handle) {
    return new LeaderLine(startNode, handle, {
      color: "black",
      size: 2,
      path: "grid",
    });
  }

  function startDrawLine(id) {
    setDragging(true);
    Object.values(draggableNodes).map((element) => (element.disabled = true));

    const startNode = document.getElementById(id).parentElement;
    let handler = document.getElementById("handler-" + id);

    if (!handler) {
      handler = window
        .$("<div />", { class: "handler", id: "handler-" + id })
        .css({
          top: currentMousePosition.y + 10 + "px",
          left: currentMousePosition.x + 10 + "px",
        })
        .appendTo("#drawboardContent");
    }

    const currentLine = newLine(
      startNode,
      document.getElementById("handler-" + id)
    );
    const drghandler = new PlainDraggable(
      document.getElementById("handler-" + id),
      {
        onMove: function (newPosition) {
          const nodeToLink = findNodeToLink(startNode);
          currentLine.position().show();
          if (nodeToLink) {
            nodeToLink.classList.toggle("node-hover", true);
          } else {
            window.$(".node").removeClass("node-hover");
          }
        },
        onDragEnd: async function () {
          const nodeToLink = findNodeToLink(startNode);
          handler.remove();
          if (nodeToLink) {
            drghandler.remove();
            currentLine.setOptions({
              end: nodeToLink,
              endPlug: "arrow1",
            });
            links.push({
              startNode: startNode.id,
              endNode: nodeToLink.id,
              line: currentLine,
            });
            await updateById(startNode.id, { targetLinkId: nodeToLink.id });
            nodeToLink.classList.toggle("node-hover");
          } else {
            currentLine.hide("none");
          }
          window.$(".node").removeClass("node-hover");
        },
      }
    );
  }

  const createDraggableNode = (container) => {
    if (document.getElementById(container.id) === null) {
      return;
    }

    const draggableNode = new PlainDraggable(
      document.getElementById(container.id),
      {
        leftTop: true,
        onMove: function (newPosition) {
          setDragging(true);
          const foundLinks = findLinks(this.element.id);
          if (foundLinks.length > 0) {
            foundLinks.map((link) => link.line.position());
          }
        },
        onDragEnd: async function (position) {
          if (
            position.left !== container.left &&
            position.top !== container.top
          ) {
            await updateById(container.id, {
              left: position.left,
              top: position.top,
            });
            setDragging(false);
          }
        },
      }
    );

    draggableNode.snap = { step: 11 };
    draggableNode.top = container.top;
    draggableNode.left = container.left;
    return draggableNode;
  };

  function stopDrawLine() {
    setDragging(false);
    Object.values(draggableNodes).map((element) => (element.disabled = false));
  }

  function isCollide(a, b) {
    return !(
      a.y + a.height < b.y ||
      a.y > b.y + b.height ||
      a.x + a.width < b.x ||
      a.x > b.x + b.width
    );
  }

  function renderContainer(container) {
    return createElement(Container, {
      container: container,
      key: container.id,
      onLoad: () => {
        setReadyCount(readyCount + 1);
      },
    });
  }

  const initContainers = async (containersHttpResponse) => {
    Object.values(containersHttpResponse).forEach((container) => {
      container.ref = createRef();
      container.startDrawLine = startDrawLine;
      container.stopDrawLine = stopDrawLine;
      container.deleteContainer = deleteContainer;
      container.viewContainer = viewContainer;
      containers[container.id] = container;
    });
    setContainers(containers);
  };

  const renderContainers = async () => {
    initContainers(containersHttpResponse);
    Object.values(containers).forEach((container) => {
      draggableNodes[container.id] = createDraggableNode(container);
    });
    setDraggableNodes(draggableNodes);
    renderLinks();
  };

  window.$(document).on("mousemove", (event) => {
    var x = event.pageX + 10;
    var y = event.pageY;
    window
      .$("#pointer")
      .css("top", y + "px")
      .css("left", x + "px");
    currentMousePosition.x = event.pageX;
    currentMousePosition.y = event.pageY;
  });

  window.$(document).on("mouseup", (event) => {
    if (dragging === true) {
      stopDrawLine();
    }
  });

  useEffect(() => {
    if (readyCount === 0 && containersHttpResponse) {
      renderContainers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containers, containersHttpResponse, readyCount]);

  return (
    <>
      {containersHttpResponse &&
        Object.values(containersHttpResponse).map((container) =>
          renderContainer(container)
        )}
    </>
  );
};

export default ContainerListRenderer;
