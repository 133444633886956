import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PayPalButtons } from "@paypal/react-paypal-js";

const OneTimeButtonWrapper = ({ setPlan }) => {
  function paymentApproved(data) {
    fetch("/api/payment/onetime", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.error) {
        console.error(response.error);
        return;
      }
      setPlan("ONE_TIME_PAYMENT");
    });
  }

  return (
    <PayPalButtons
      onApprove={paymentApproved}
      createOrder={(data, actions) => {
        return actions.order
          .create({
            purchase_units: [
              {
                amount: {
                  value: "2.99",
                },
              },
            ],
            payment_source: {
              paypal: {
                experience_context: {
                  brand_name: "Appulses.com",
                  locale: "en-US",
                  user_action: "PAY_NOW",
                  shipping_preference: "NO_SHIPPING",
                },
              },
            },
          })
          .then((orderId) => {
            // Your code here after create the order
            return orderId;
          });
      }}
      style={{
        label: "buynow",
      }}
    />
  );
};

const OneTimePayment = ({ showButton, setPlan }) => {
  return (
    <div className="card">
      <div className="card-header">3-DAYS</div>
      <div className="card-body">
        <div className="card-title">
          <h4>
            <b>2.99$</b>/3 days
          </h4>
        </div>
        <ul className="list-group">
          <li className="list-group-item">
            sections{" "}
            <span className="badge bg-primary rounded-pill float-end">1</span>
          </li>
          <li className="list-group-item">
            accounts{" "}
            <span className="badge bg-primary rounded-pill float-end">1</span>
          </li>
          <li className="list-group-item">
            containers{" "}
            <span className="badge bg-primary rounded-pill float-end">10</span>
          </li>
          <li className="list-group-item">
            API
            <span className="badge bg-success rounded-pill float-end">
              <i className="bi bi-check"></i>
            </span>
          </li>
          <li className="list-group-item">
            Permissions{" "}
            <span className="badge bg-danger rounded-pill float-end">
              <i className="bi bi-x-lg"></i>
            </span>
          </li>
        </ul>
      </div>
      {showButton ? (
        <PayPalScriptProvider
          options={{
            clientId: process.env.REACT_APP_CLIENT_ID,
            components: "buttons",
          }}
        >
          <OneTimeButtonWrapper showButton={showButton} setPlan={setPlan} />
        </PayPalScriptProvider>
      ) : null}
    </div>
  );
};

export default OneTimePayment;
