import { ButtonWrapper } from "./ButtonWrapper";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const Premium = ({ showSubscribe, setPlan }) => {
  return (
    <div className="card">
      <div className="card-header">Premium</div>
      <div className="card-body">
        <div className="card-title">
          <span className="plan-price">
            <b>16$</b>
          </span>{" "}
          per user per month
        </div>
        <ul className="list-group">
          <li className="list-group-item">
            sections{" "}
            <span className="badge bg-primary rounded-pill float-end">
              unlimited
            </span>
          </li>
          <li className="list-group-item">
            accounts
            <span className="badge bg-primary rounded-pill float-end">10</span>
          </li>
          <li className="list-group-item">
            containers{" "}
            <span className="badge bg-primary rounded-pill float-end">
              unlimited
            </span>
          </li>
          <li className="list-group-item">
            API
            <span className="badge bg-success rounded-pill float-end">
              <i className="bi bi-check"></i>
            </span>
          </li>
          <li className="list-group-item">
            Permissions
            <span className="badge bg-success rounded-pill float-end">
              <i className="bi bi-check"></i>
            </span>
          </li>
        </ul>
      </div>
      {showSubscribe ? (
        <PayPalScriptProvider
          options={{
            clientId: process.env.REACT_APP_CLIENT_ID,
            components: "buttons",
            intent: "subscription",
            vault: true,
          }}
        >
          <ButtonWrapper
            type="subscription"
            planId={process.env.REACT_APP_PREMIUM_PLAN_ID}
            setPlan={setPlan}
          />
        </PayPalScriptProvider>
      ) : null}
    </div>
  );
};
export default Premium;
