import { useEffect, useState } from "react";
import { useContext } from "react";
import { StoreContext } from "../../../api/StoreContext";

const Permissions = () => {
  const apiStore = useContext(StoreContext);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (apiStore && apiStore.accounts && apiStore.accounts.length > 0) {
      setAccounts(apiStore.accounts);
    } else {
      setAccounts([]);
    }
  }, [apiStore]);

  const accountRow = (account) => {
    return (
      <tr>
        <td>{account.email}</td>
        <td>{account.permission}</td>
        <td>
          <button className="btn btn-danger">Remove</button>
        </td>
      </tr>
    );
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="form-">
              <label htmlFor="inputPassword5" className="form-label">
                Invate user
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="User email"
              />
              <button className="btn btn-primary mt-3">Invate</button>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accounts.map((account) => accountRow(account))}
                      {accounts.length === 0 ? (
                        <tr>
                          <td></td>
                          <td>No accounts found</td>
                          <td></td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Permissions;
