import ApiDocumentation from "./ApiDocumentation.js";
import ApiRelations from "./ApiRelations.js";
import Description from "./Description";

const DetailsModal = ({ container, saveNodeName, viewContainer }) => {
  return (
    <div
      className="modal containerDetailsModal"
      tabIndex="-1"
      id="containerModal"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-10">
                  <div id="editNode" className="input-group">
                    <input
                      id="currentNodeName"
                      type="text"
                      className="form-control"
                    />
                    <input type="hidden" id="currentNodeId" />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="saveNodeName"
                      onClick={saveNodeName}
                    >
                      save
                    </button>
                  </div>
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="btn-close node-close-button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body" id="relations-modal-body">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item active" role="presentation">
                <button
                  className="nav-link active"
                  id="api-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#relations"
                  type="button"
                  role="tab"
                  aria-controls="relations"
                  aria-selected="true"
                >
                  API relations
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="api-documentation-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#api-documentation"
                  type="button"
                  role="tab"
                  aria-controls="api-documentation"
                  aria-selected="false"
                >
                  OpenAPI documentation
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  id="description-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#reaadme"
                  type="button"
                  role="tab"
                  aria-controls="reaadme"
                  aria-selected="false"
                >
                  Description
                </button>
              </li>
            </ul>
            <div className="tab-content" id="container-tabs">
              <div
                className="tab-pane show active fade mt-3"
                id="relations"
                role="tabpanel"
                aria-labelledby="api-tab"
              >
                {
                  <ApiRelations
                    container={container}
                    viewContainer={viewContainer}
                  />
                }
              </div>
              <div
                className="tab-pane fade mt-3"
                id="api-documentation"
                role="tabpanel"
                aria-labelledby="api-documentation-tab"
              >
                {<ApiDocumentation container={container} />}
              </div>
              <div
                className="tab-pane fade mt-3"
                id="reaadme"
                role="tabpanel"
                aria-labelledby="description-tab"
              >
                <Description container={container} />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsModal;
