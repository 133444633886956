const ApiClient = (location) => {
  const CONTAINERS_PATH = "/containers";
  const SECTIONS_CONTAINERS_PATH = `${CONTAINERS_PATH}/section`;
  const SECTIONS_PATH = "/sections";

  const getApiPath = () => {
    return "/api";
  };

  const getSectionId = () => {
    return location.pathname.split("/").pop();
  };

  async function getCsrfToken() {
    const csrf = await fetch(`${getApiPath()}/csrf`, {
      method: "GET",
    });
    return await csrf.json();
  }

  async function fetchData() {
    const userData = await getUserData();
    if (userData === undefined || !userData) {
      return;
    }
    const sections = await fetchSections();

    if (location.pathname.includes("/section")) {
      const sectionId = getSectionId();
      const containers = await fetchContainers(sectionId);
      return {
        userData: userData,
        sections: sections,
        containers: containers,
      };
    }
    return {
      userData: userData,
      sections: sections,
      containers: [],
    };
  }

  const fetchApiClientId = async () => {
    const response = await fetch(`${getApiPath()}/api-keys`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) return;
    return await response.json();
  };

  const generateApiKeys = async (setApiKeys) => {
    const response = await fetch(`${getApiPath()}/create-api-keys`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (data.clientSecret) {
      setApiKeys(data);
    }
    return data;
  };

  async function getUserData() {
    const response = await fetch(`${getApiPath()}/userinfo`, {
      method: "get",
      redirect: "manual",
    });
    if (response.status >= 300) {
      return;
    }
    const userData = response.json();
    if (
      userData.id &&
      userData.id !== process.env.REACT_APP_ANONYMOUS_USER_ID
    ) {
      const apiKeys = await fetchApiClientId();
      userData.apiKeys = apiKeys;
    }
    localStorage.setItem("userData", JSON.stringify(userData));
    return userData;
  }

  async function getNodeById(id) {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetch(`${getApiPath()}${CONTAINERS_PATH}/${id}`, options).then(
      (res) => res.json()
    );
  }
  async function deleteLinkById(id) {
    const options = {
      method: "DELETE",
    };
    return fetch("/api/links/" + id, options)
      .then(() => true)
      .catch((e) => {
        return false;
      });
  }

  async function deleteById(id) {
    const options = {
      method: "DELETE",
    };
    return fetch("/api/containers/" + id, options)
      .then(() => true)
      .catch((e) => {
        return false;
      });
  }

  const fetchSections = async () => {
    const res = await fetch(`${getApiPath()}${SECTIONS_PATH}`);
    if (res.status === 401) {
      return [];
    }
    const responseBody = await res.json();
    return responseBody;
  };

  const fetchContainers = async (sectionId) => {
    const res = await fetch(
      `${getApiPath()}${SECTIONS_CONTAINERS_PATH}/${sectionId}`
    );
    return await res.json();
  };

  const getDashboardPath = () => {
    const dashboardPath = `/dashboard`;
    return dashboardPath;
  };

  const getSectionsPath = async () => {
    const dashboardPath = `/dashboard/section`;
    return dashboardPath;
  };
  const createSection = async (section) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(section),
    };
    const res = await fetch(`${getApiPath()}${SECTIONS_PATH}`, options);
    if (res.status === 204) {
      return res.json();
    } else {
      return false;
    }
  };

  const updateSection = async (section) => {
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sectionName: section.name,
        sectionDescription: section.description,
      }),
    };
    const res = await fetch(
      `${getApiPath()}${SECTIONS_PATH}/${section.id}`,
      options
    );
    if (res.status === 204) {
      return true;
    } else {
      return false;
    }
  };

  async function updateContainerById(id, updateRequest) {
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateRequest),
    };
    return fetch(`${getApiPath()}${CONTAINERS_PATH}/${id}`, options)
      .then(() => true)
      .catch((e) => {
        return false;
      });
  }

  const deleteSection = async (section) => {
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await fetch(
      `${getApiPath()}${SECTIONS_PATH}/${section.id}`,
      options
    );
    if (res.status === 204) {
      return true;
    } else {
      return false;
    }
  };

  const updateContainer = async (payload) => {
    const bodyRequest = {
      name: payload.name,
      nameChanged: payload.nameChanged || false,
    };
    if (payload.top) {
      bodyRequest.top = payload.top;
    }
    if (payload.left) {
      bodyRequest.left = payload.left;
    }
    if (payload.description) {
      bodyRequest.description = payload.description;
    }
    if (payload.sectionId) {
      bodyRequest.sectionId = payload.sectionId;
    }
    payload.body = JSON.stringify(bodyRequest);
    const options = {
      method: payload.method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyRequest),
    };
    return fetch(
      `${getApiPath()}${CONTAINERS_PATH}/${payload.containerId}`,
      options
    ).then((res) => {
      if (
        (payload.method === "POST" && res.status !== 200) ||
        (payload.method === "PATCH" && res.status !== 204)
      ) {
        throw new Error("Could not save the node");
      }
    });
  };

  const createContainer = async (payload) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    const containerResponse = await fetch(
      `${getApiPath()}${CONTAINERS_PATH}`,
      options
    );

    if (containerResponse.status !== 200) {
      throw new Error("Could not create the container");
    } else {
      window.location.reload();
    }
  };

  const getApiSpecPath = (containerId) => {
    return `${getApiPath()}${CONTAINERS_PATH}/${containerId}/apiDefinition`;
  };

  const logout = async () => {
    localStorage.clear("userData");
    const options = {
      method: "POST",
      redirect: "manual",
    };
    fetch(`${getApiPath()}/logout`, options)
      .then(() => {
        window.location = "/";
      })
      .catch((err) => {});
  };

  return {
    getApiSpecPath: (containerId) => getApiSpecPath(containerId),
    updateContainer: (payload) => updateContainer(payload),
    createContainer: (payload) => createContainer(payload),
    updateContainerById: (id, updateRequest) =>
      updateContainerById(id, updateRequest),
    generateApiKeys: (setApiKeys) => generateApiKeys(setApiKeys),
    fetchApiClientId: () => fetchApiClientId(),
    fetchData: () => fetchData(),
    getApiPath: () => getApiPath(),
    getUserData: () => getUserData(),
    getNodeById: (id) => getNodeById(id),
    deleteById: (id) => deleteById(id),
    deleteLinkById: (id) => deleteLinkById(id),
    fetchSections: () => fetchSections(),
    fetchContainers: (sectionId) => fetchContainers(sectionId),
    getDashboardPath: () => getDashboardPath(),
    getSectionsPath: () => getSectionsPath(),
    createSection: (section) => createSection(section),
    updateSection: (section) => updateSection(section),
    deleteSection: (section) => deleteSection(section),
    logout: () => logout(),
    getCsrfToken: () => getCsrfToken(),
  };
};

export default ApiClient;
