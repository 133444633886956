import Navbar from "../Navbar";
import Footer from "../Footer";

const ContactForm = () => {
  return (
    <>
      <Navbar />
      <main>
        <div className="container pt-4">
          <p className="pt-5">
            We are a Polish startup whose goal is to facilitate the creation of
            documentation for information systems
          </p>
          <p>
            Our team consists of experienced developers and analysts who have
            been working in the IT industry for many years.
          </p>
          <p className="pb-2">
            We are constantly developing our product and we are open to any
            suggestions for its improvement.
          </p>
          <h3>Appulses.com</h3>
          <p>ul. Stanisława Taczaka 24</p>
          <p>61-819 Poznań</p>
          <p className="pb-4"></p>
          <p>
            For any questions or concerns, please contact us at{" "}
            <a href="mailto:support@appulses.com">support@appulses.com</a>
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ContactForm;
