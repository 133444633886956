import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../api/StoreContext";
import Section from "./Section";

function SectionsList() {
  const apiStore = useContext(StoreContext);
  const [sections, setSections] = useState();
  useEffect(() => {
    if (apiStore && apiStore.sections && apiStore.sections.length > 0) {
      setSections(apiStore.sections);
    }
  }, [apiStore, sections]);
  return (
    <div className="sections-list container-lg pt-5 mt-3">
      <div className="row">
        {sections &&
          sections.map((section) => (
            <Section section={section} key={section.id} />
          ))}
      </div>
    </div>
  );
}

export default SectionsList;
