import { useEffect, useState } from "react";
import { useContext } from "react";
import { StoreContext } from "../../../api/StoreContext";
import ApiClient from "../../../api/ApiClient";
const ApiMainView = () => {
  const apiStore = useContext(StoreContext);
  const [apiKeys, setApiKeys] = useState(null);
  const apiClient = ApiClient();

  useEffect(() => {
    if (
      !apiKeys &&
      apiStore &&
      apiStore.userData &&
      apiStore.userData.clientId
    ) {
      setApiKeys({ clientId: apiStore.userData.clientId, clientSecret: null });
    }
  }, [apiStore, apiKeys]);
  return (
    <div className="container">
      <h2>API access</h2>
      <div className="row">
        <div className="alert alert-primary" role="alert">
          <p>
            You can find more information about the API in our{" "}
            <a href="/api/documentation">documentation</a>.
          </p>
          <p>
            Please be aware that you can see generated client secret only once.
          </p>
        </div>
      </div>
      <div className="row">
        {apiKeys && (
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <b>client id</b>
                      </td>
                      <td>
                        <b>client secret</b>
                      </td>
                    </tr>
                    <tr>
                      <td>{apiKeys.clientId}</td>
                      <td>
                        {apiKeys.clientSecret
                          ? apiKeys.clientSecret
                          : "******************************"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button className="btn btn-primary">Regenerate</button>
              </div>
            </div>
          </div>
        )}
        {!apiKeys && (
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <p>
                  You don't have any API keys yet. Please generate one to start
                  using our API.
                </p>
                <button
                  className="btn btn-primary"
                  onClick={() => apiClient.generateApiKeys(setApiKeys)}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApiMainView;
