import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import logo from "../img/logo.jpg";
import "../css/app.css";
import { UserContext } from "../auth/UserContext";

function PrimaryLinks({ activePath }) {
  return (
    <ul className="nav nav-pills">
      <li className="nav-item">
        <a
          href="/"
          className={"/" === activePath ? "nav-link active" : "nav-link"}
          aria-current="page"
        >
          Home
        </a>
      </li>
      <li className="nav-item">
        <a
          href="/pricing"
          className={"/pricing" === activePath ? "nav-link active" : "nav-link"}
        >
          Pricing
        </a>
      </li>
      <li className="nav-item">
        <a
          href="/contact"
          className={"/contact" === activePath ? "nav-link active" : "nav-link"}
        >
          Contact
        </a>
      </li>
    </ul>
  );
}

function NavbarLinks({ isLogged }) {
  if (isLogged) {
    return (
      <a href="/dashboard" className="btn btn-primary dashboard">
        Dashboard
      </a>
    );
  }
  return (
    <a href="/login" className="btn btn-primary sign-in">
      Sign In
    </a>
  );
}

function Navbar() {
  const { isLogged } = useContext(UserContext);
  const location = useLocation();
  const activePath = location.pathname;
  useEffect(() => {}, [isLogged]);
  return (
    <nav className="py-2 border-bottom navbar-custom">
      <header className="d-flex flex-wrap justify-content-center py-3 ps-md-3 me-md-3">
        <a
          href="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        <PrimaryLinks activePath={activePath} />
        <NavbarLinks isLogged={isLogged} />
      </header>
    </nav>
  );
}

export default Navbar;
