const Container = ({ container }) => {
  const style = {
    top: "0px",
    left: "0px",
  };
  return (
    <div className="node" id={container.id} style={style} ref={container.ref}>
      <div
        title="Click to create connection"
        className="linkButton nodeButton"
        id={"linkButton-" + container.id}
        onClick={() => container.startDrawLine("linkButton-" + container.id)}
        onMouseUp={() => container.stopDrawLine()}
      ></div>
      <div
        className="editButton nodeButton bi bi-pencil-fill"
        id={"editButton-" + container.id}
        onClick={() => container.viewContainer(container.id)}
      ></div>
      <div
        className="deleteButton nodeButton"
        id={"deleteButton-" + container.id}
        onClick={() => container.deleteContainer(container.id)}
      ></div>
      <div
        className="node-title"
        id={"title-" + container.id}
        onClick={() => container.viewContainer(container.id)}
      >
        {container.name.length > 50
          ? container.name.substring(0, 50) + "..."
          : container.name}
      </div>
      <div
        className="node-description"
        id={"description-" + container.id}
      ></div>
    </div>
  );
};

export default Container;
