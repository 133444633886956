import Navbar from "../pages/Navbar";
import ApiClient from "../api/ApiClient";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export default function Login() {
  const [csrfToken, setCsrfToken] = useState(null);
  const apiClient = ApiClient();

  const getToken = async () => {
    const csrfToken = await apiClient.getCsrfToken();
    setCsrfToken(csrfToken);
  };

  useEffect(() => {
    if (!csrfToken) {
      getToken();
    }
    ReactGA.send({
      hitType: "pageview",
      title: "Registration and login page",
    });
  });

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const validateEmailAndPassword = (email, password) => {
    const errorMessage = document.getElementById("error-message");
    if (email === "" || password === "") {
      errorMessage.innerHTML = "Please enter a valid email and password";
      errorMessage.style.display = "block";
      return false;
    }
    if (!validateEmail(email)) {
      errorMessage.innerHTML = "Please enter a valid email address";
      errorMessage.style.display = "block";
      return false;
    }
    return true;
  };
  const login = (e) => {
    e.preventDefault();
    const email = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    if (!validateEmailAndPassword(email, password)) {
      return;
    }
    const data = {
      username: email,
      password: password,
    };
    fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-TOKEN": csrfToken.token,
      },
      body: Object.entries(data)
        .map(
          ([key, value]) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(value)
        )
        .join("&"),
    })
      .then((response) => {
        if (response.status === 200) {
          window.location.href = "/dashboard";
        } else {
          document.getElementById("error-message").innerHTML =
            "Invalid email or password";
          document.getElementById("error-message").style.display = "block";
        }
      })
      .catch((error) => {
        getToken();
        console.error("Error:", error);
      });
  };
  return (
    <>
      <Navbar />
      <main>
        <div className="container mt-5 login-page">
          <div className="row">
            <div className="col">
              <div id="error-message" className="alert alert-danger"></div>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-4 flex-column align-self-center">
              <h2>Login</h2>
              <form action="#" method="post">
                <div className="form-group">
                  <label htmlFor="username">E-mail</label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    required
                  />
                </div>
                {csrfToken && (
                  <input name="_csrf" type="hidden" value={csrfToken.token} />
                )}
                <div className="form-group mt-3">
                  <button onClick={login} className="btn btn-primary">
                    Login
                  </button>
                </div>
                <div className="form-group mt-3">
                  <a href="/forgot-password">Forgot password?</a>
                </div>
              </form>
            </div>

            <div className="col-4 flex-column">
              <h2>Account access</h2>
              <p>
                If you do not have an account, you can request one by sending an
                email to{" "}
                <a href="mailto:support@appulses.com">support@appulses.com</a>.
                We will send you an email with a link to register.
              </p>
              <p>
                Please note that we only accept requests from registered
                companies.
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
